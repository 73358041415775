import {getInstance} from "@/helpers/axios.service";
import {endPoint} from "@/helpers/Endpoint";

export const toolService = {
    get, gets, add, update, statusChange
};

async function get(request = {}) {
    return await getInstance(endPoint.toolGet, request);
}

async function gets(request = {}) {
    return await getInstance(endPoint.toolGets, request);
}

async function add(request = {}) {
    return await getInstance(endPoint.toolAdd, request);
}

async function update(request = {}) {
    return await getInstance(endPoint.toolUpdate, request);
}

async function statusChange(request = {}) {
    return await getInstance(endPoint.toolStatusChange, request);
}
